import { Component, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {
  MaterialPackage,
  ReferencedMaterial
} from '@app/modules/shared/models/product.model';
import { GetDefaultDescriptionPipe } from '@app/modules/shared/pipes/get-default-description/get-default-description.pipe';

@Component({
  selector: 'app-product-referenced-material-data',
  templateUrl: './product-referenced-material-data.component.html',
  styleUrls: ['./product-referenced-material-data.component.sass']
})
export class ProductReferencedMaterialDataComponent {
  dataSource = new MatTableDataSource([]);

  _referencedMaterial: ReferencedMaterial;
  @Input()
  set referencedMaterial(value) {
    if (value?.materialPackages.length > 0) {
      this.dataSource = new MatTableDataSource(
        value.materialPackages
      ) as MatTableDataSource<MaterialPackage>;
    }
    this._referencedMaterial = value;
  }

  get referencedMaterial() {
    return this._referencedMaterial;
  }

  @Input() bbraunProductPageFlag = false;

  displayedColumns = ['gtin', 'packagingUnit', 'unitOfMeasure', 'pzn'];

  rowsFn: (entity: any) => Array<{ key?: string; label: string; value?: any }> =
    (entity: ReferencedMaterial) => [
      { key: 'itemCode', label: 'B.Braun Code' },
      { key: 'salesStatus', label: 'Sale Status' },
      ...(entity?.texts.length > 0
        ? [
            {
              label: 'Description',
              value: new GetDefaultDescriptionPipe().transform(entity)
            }
          ]
        : [
            {
              label: 'Description',
              value: '-'
            }
          ]),
      { key: 'productClass', label: 'Product Class' },
      { key: 'marketingRelease', label: 'Marketing Release' }
    ];

  rowsFnBbraunPage: (
    entity: any
  ) => Array<{ key?: string; label: string; value?: any }> = (
    entity: ReferencedMaterial
  ) => [
    { key: 'itemCode', label: 'Competitor Product Code' },
    { key: 'manufacturerName', label: 'Manufacturer Name' },
    ...(entity?.texts.length > 0
      ? this.generateDescription(entity?.texts)
      : [
          {
            label: 'Description',
            value: '-'
          }
        ]),
    { key: 'productClass', label: 'Product Class' },
    { key: 'nationalDrugNumber', label: 'NDC' }
  ];

  private generateDescription(texts: Array<{ [key: string]: string }>) {
    return texts.map((item) => ({
      label: `Description (${item.language})`,
      value: item.value
    }));
  }
}
