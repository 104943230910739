import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { CxBannerModule } from '@bbraun/cortex/banner';
import { CxDialogModule } from '@bbraun/cortex/dialog';
import { CxFloatingButtonModule } from '@bbraun/cortex/floating-button';
import { CxFooterModule } from '@bbraun/cortex/footer';
import { CxHeaderModule } from '@bbraun/cortex/header';
import { CxImageModule } from '@bbraun/cortex/image';
import { CxNumberInputModule } from '@bbraun/cortex/number-input';
import { CxSearchBarModule } from '@bbraun/cortex/search-bar';
import { CxSnackbarModule, SNACKBAR_CONFIG } from '@bbraun/cortex/snackbar';
import { CxHeartBeatModule, CxTableModule } from '@bbraun/cortex/table';
import { QuicklinkModule } from 'ngx-quicklink';

import { CompetitorInputWithChipsComponent } from './competitor-input-with-chips/competitor-input-with-chips.component';
import { AccordionWrapperComponent } from './components/accordion-wrapper/accordion-wrapper.component';
import { BadgeComponent } from './components/badge/badge.component';
import { DetailsListComponent } from './components/details-list/details-list.component';
import { FooterComponent } from './components/footer/footer.component';
import { FormFieldErrorsComponent } from './components/form/form-field-errors/form-field-errors.component';
import { HeaderComponent } from './components/header/header.component';
import { CompositeEditSectionsComponent } from './components/product-reference-list/composite-edit-sections/composite-edit-sections.component';
import { ProductReferenceListComponent } from './components/product-reference-list/product-reference-list.component';
import { ProductReferencedMaterialDataComponent } from './components/product-referenced-material-data/product-referenced-material-data.component';
import { ProductSearchEmptyComponent } from './components/product-search-empty/product-search-empty.component';
import { SearchBarFormHandlerComponent } from './components/search-bar-form-handler/search-bar-form-handler.component';
import { TableNoResultsComponent } from './components/table-no-results/table-no-results.component';
import { ThumbnailImageComponent } from './components/thumbnail-image/thumbnail-image.component';
import { BbraunPaginatorDirective } from './directives/bbraun-paginator.directive';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { DefaultImageDirective } from './directives/default-image.directive';
import { InvalidControlScrollDirective } from './directives/invalid-control-scroll.directive';
import { InvalidControlScrollContainerDirective } from './directives/invalid-control-scroll-container.directive';
import { MapIconDirective } from './directives/map-icon.directive';
import { HeaderInterceptor } from './interceptors/https.interceptor';
import { GetDefaultDescriptionPipe } from './pipes/get-default-description/get-default-description.pipe';
import { GetFieldsFromBasePipe } from './pipes/get-fields-from-base/get-fields-from-base.pipe';
import { HeaderOptionPipe } from './pipes/header-option/header-option.pipe';
import { JoinPipe } from './pipes/join/join.pipe';
import { HandleResponseService } from './service/handle-response/handle-response.service';
import { HttpService } from './service/http/http.service';
import { TransformTypeReferenceScreenPipe } from './pipes/transform-type-by-reference-screen/transform-type-reference-screen.pipe';
import { HighlightTextPipe } from './pipes/highlight-text/highlight-text.pipe';
import { SortKeysReversePipe } from './pipes/sort-key-reverse/sort-keys-reverse.pipe';

export const INTERCEPTORS = [
  // interceptor works only for 1 module, so we should import it everywhere
  { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true }
];

const modules = [
  QuicklinkModule,
  FormsModule,
  ReactiveFormsModule,
  // Material Modules
  MatButtonModule,
  MatIconModule,
  MatInputModule,
  MatAutocompleteModule,
  MatFormFieldModule,
  MatSortModule,
  MatTableModule,
  MatSelectModule,
  MatChipsModule,
  MatTooltipModule,
  MatProgressSpinnerModule,
  MatPaginatorModule,
  MatCardModule,
  MatListModule,
  MatTabsModule,
  MatDialogModule,
  MatExpansionModule,
  MatRadioModule,
  // Cx Modules
  CxHeaderModule,
  CxFooterModule,
  CxFloatingButtonModule,
  CxTableModule,
  CxBannerModule,
  CxDialogModule,
  CxHeartBeatModule,
  CxSearchBarModule,
  CxSnackbarModule,
  CxImageModule,
  CxNumberInputModule
];

const components = [
  HeaderComponent,
  FooterComponent,
  AccordionWrapperComponent,
  TableNoResultsComponent,
  FormFieldErrorsComponent,
  BadgeComponent,
  DetailsListComponent,
  CompetitorInputWithChipsComponent,
  ThumbnailImageComponent,
  ProductReferencedMaterialDataComponent,
  SearchBarFormHandlerComponent,
  ProductSearchEmptyComponent,
  ProductReferenceListComponent,
  CompositeEditSectionsComponent
];

const directives = [
  DefaultImageDirective,
  BbraunPaginatorDirective,
  ClickOutsideDirective,
  InvalidControlScrollContainerDirective,
  InvalidControlScrollDirective,
  MapIconDirective,
  JoinPipe,
  HeaderOptionPipe,
  GetDefaultDescriptionPipe,
  GetFieldsFromBasePipe,
  HighlightTextPipe,
  SortKeysReversePipe,
  TransformTypeReferenceScreenPipe
];

@NgModule({
  imports: [
    ...modules,
    CommonModule,
    HttpClientModule,
    RouterModule,
    MatDialogModule,
    MatExpansionModule,
    MatTableModule,
    MatChipsModule
  ],
  declarations: [...components, ...directives],
  exports: [...modules, ...directives, ...components],
  providers: [
    INTERCEPTORS,
    HttpService,
    HandleResponseService,
    {
      provide: SNACKBAR_CONFIG,
      useValue: {
        closeButton: true
      }
    }
  ]
})
export class SharedModule {}
