import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TABLE_OPTIONS } from '@app/modules/shared/constants/table/table.constants';
import {
  MaterialFilter,
  PreviewBBraunProduct,
  Product,
  ProductReference,
  ProductReferenceOverview,
  ProductRestriction,
  ReferencedMaterial,
} from '@app/modules/shared/models/product.model';
import { HttpParamsFactory } from '@shared/factory/http-params/http-params.factory';
import {
  PageConfig,
  PageDataConfig,
  SortConfig
} from '@shared/interfaces/page/page-data-config.interface';
import { PagingResponse } from '@shared/interfaces/responses/paging-response.interface';
import { HttpService } from '@shared/service/http/http.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  constructor(private readonly httpService: HttpService) {}

  getProduct(id: number): Observable<Product> {
    return this.httpService.request('materials/' + id).get();
  }

  getProductRestrictions(
    id: number
  ): Observable<PagingResponse<ProductRestriction>> {
    return this.httpService.request('materials/' + id + '/restrictions').get();
  }

  deleteProduct(id: number): Observable<Product> {
    return this.httpService.request('materials/' + id).delete();
  }

  getProducts(): Observable<PagingResponse<Product>> {
    const options: PageDataConfig = {
      page: TABLE_OPTIONS.PAGE,
      size: TABLE_OPTIONS.SIZE,
      sort: ''
    };
    const params = new HttpParamsFactory(options).returnParams();
    return this.httpService.request('materials').params(params).get();
  }

  createProduct(body: Product): Observable<Product> {
    return this.httpService.request('materials').body(body).post();
  }

  editProduct(id: number, body: Product): Observable<Product> {
    const { manufacturerName, ...editedProduct } = body;
    return this.httpService
      .request('materials/' + id)
      .body(editedProduct)
      .put();
  }

  filterProductByManufacturerIdAndItemCode(
    mid: number,
    itemCode: string
  ): Observable<HttpResponse<Product>> {
    return this.httpService
      .request(`materials/manufacturer-id/${mid}/item-code/${itemCode}`)
      .withFullResponse()
      .get();
  }

  filterProducts(
    filters: Array<MaterialFilter>,
    pageConfig: PageConfig,
    sortConfig: SortConfig
  ): Observable<PagingResponse<Product>> {
    const options = {
      filters,
      page: pageConfig.pageIndex,
      size: pageConfig.pageSize,
      sort: Object.values(sortConfig).every((value) => !!value)
        ? `${sortConfig.sort},${sortConfig.sortDirection}`
        : null
    };
    const params = new HttpParamsFactory(options).returnParams();
    return this.httpService.request('materials/filter').params(params).get();
  }

  getBBraunProductsByItemCode(
    itemCodeTerm
  ): Observable<PagingResponse<PreviewBBraunProduct>> {
    const options: PageDataConfig = {
      itemCodeTerm,
      page: TABLE_OPTIONS.PAGE,
      size: 20,
      sort: ''
    };
    const params = new HttpParamsFactory(options).returnParams();
    return this.httpService
      .request('materials/b-braun/searchPreview')
      .params(params)
      .get();
  }

  uploadData(body: FormData, manufacturerId: string): Observable<null> {
    return this.httpService
      .request('uploads/file')
      .param('manufacturerId', manufacturerId)
      .body(body)
      .post();
  }

  getReferences(
    id: number,
    pageConfig: PageConfig
  ): Observable<PagingResponse<ProductReference>> {
    const SIZE_FOR_REFERENCES = 10000;
    const options: PageDataConfig = {
      page: pageConfig.pageIndex,
      size: SIZE_FOR_REFERENCES,
      sort: 'country,asc'
    };
    const params = new HttpParamsFactory(options).returnParams();
    return this.httpService
      .request('materials/' + id + '/references/preview')
      .params(params)
      .get();
  }

  getReferencesOverview(
    productId,
    referenceId
  ): Observable<ProductReferenceOverview> {
    return this.httpService
      .request(
        `materials/${productId}/references/${referenceId}/items-overview`
      )
      .get();
  }

  getReferenceAcknowledgement(itemCode): Observable<ReferencedMaterial> {
    const options: PageDataConfig = {
      page: 0,
      size: 1,
      sort: '',
      itemCode
    };
    const params = new HttpParamsFactory(options).returnParams();
    return this.httpService
      .request('materials/b-braun/acknowledgement')
      .params(params)
      .post();
  }

  createReference(
    productId: number,
    body: Partial<{
      country: string;
      difference: string;
      id: number;
      items: Array<{
        materialId: number;
        ratioType: string;
        position?: number;
        quantity?: number;
      }>;
      note: string;
      ratio: string;
      similarity: string;
      type: string;
    }>
  ): Observable<ProductReference> {
    return this.httpService
      .request('materials/' + productId + '/references')
      .body(body)
      .post();
  }

  editReference(
    productId: number,
    referenceId: number,
    body: Partial<{
      country: string;
      difference: string;
      id: number;
      items: Array<{
        materialId: number;
        ratioType: string;
        position?: number;
        quantity?: number;
      }>;
      note: string;
      ratio: string;
      similarity: string;
      type: string;
    }>
  ): Observable<ProductReference> {
    return this.httpService
      .request('materials/' + productId + '/references/' + referenceId)
      .body(body)
      .put();
  }

  deleteReference(productId: number, referenceId: number) {
    return this.httpService
      .request('materials/' + productId + '/references/' + referenceId)
      .delete();
  }

  getRestrictions(
    id: string,
    pageConfig: PageConfig
  ): Observable<PagingResponse<ProductRestriction>> {
    const options: PageDataConfig = {
      page: TABLE_OPTIONS.PAGE,
      size: 6,
      sort: ''
    };
    const params = new HttpParamsFactory(options).returnParams();
    return this.httpService
      .request('materials/' + id + '/restrictions')
      .params(params)
      .get();
  }

  createRestriction(
    productId: number,
    productRestriction: ProductRestriction
  ): Observable<ProductRestriction> {
    return this.httpService
      .request('materials/' + productId + '/restrictions')
      .body(productRestriction)
      .post();
  }

  editRestriction(
    productId: number,
    restrictionId: number,
    body: ProductRestriction
  ): Observable<ProductRestriction> {
    return this.httpService
      .request('materials/' + productId + '/restrictions/' + restrictionId)
      .body(body)
      .put();
  }

  deleteRestriction(productId: number, restrictionId: number) {
    return this.httpService
      .request('materials/' + productId + '/restrictions/' + restrictionId)
      .delete();
  }

}
