<mat-list dense [ngClass]="{ 'list-with-border': bordered }">
  <ng-content></ng-content>
  <mat-list-item *ngFor="let row of rows">
    <span>
      {{ row.label }}
    </span>
    <span>
      {{ row.value ?? entity?.[row.key] ?? '-' }}
    </span>
  </mat-list-item>
</mat-list>
