import { PagingResponse } from '@app/modules/shared/interfaces/responses/paging-response.interface';

import {
  RestrictionTypes,
  SimilarityTypes
} from '../constants/competitor-products/competitor-products.constant';

export interface ProductDescription {
  language: string;
  value: string;
}
export interface competitorNameInterface {
  id: number;
  name: string;
}

export interface MaterialPackage {
  type: string;
  globalTradeItemNumber: string;
  pharmaceuticalNumber: string;
  unitOfMeasure: number;
}

export interface MaterialFilter {
  type: string;
  text: string;
}

export interface Product {
  id?: number;
  itemCode: string;
  source: string;
  manufacturerId: number;
  manufacturerName: string;
  texts?: Array<ProductDescription>;
  productClass?: string;
  readOnly?: boolean;
  nationalDrugNumber?: string;
  materialPackages?: Array<MaterialPackage>;
}

export interface RestProduct {
  id?: number;
  source: string;
  manufacturerId: number;
  texts?: Array<ProductDescription>;
  productClass?: string;
  nationalDrugNumber?: string;
  materialPackages?: Array<MaterialPackage>;
}

export interface ProductClass {
  id: number;
  name: string;
}

export interface BbraunCode {
  itemCode: string;
  description: string;
  salesStatus: string;
}

export interface CompetitorProductDetailsInterface {
  label: string;
  value: string;
}

export enum CompetitorProductType {
  DETAILS = 'Details'
}

export enum CompetitorProductAddonType {
  REFERENCE = 'Reference',
  RESTRICTION = 'Restriction'
}

export interface PackageInfoDataInterface {
  id: number;
  globalTradeItemNumber: number;
  type: string;
  unitOfMeasure: number;
  pharmaceuticalNumber: string;
}

export interface ReferenceCountry {
  value: string;
  viewValue: string;
}

export interface Country {
  value: string;
  name: string;
}

export interface PreviewBBraunProduct {
  itemCode: string;
  description: string;
  source: string;
  salesStatus: string;
}

export interface ProductReferenceItemMaterial {
  id: number;
  itemCode: string;
  manufacturerId: number;
  manufacturerName: string;
  source: string;
  defaultText: string;
}
export interface ProductReferenceItem {
  ratioType: string;
  position: number;
  quantity: number;
  material: ProductReferenceItemMaterial;
  manufacturerName: string;
}
export interface ProductReference {
  id: number;
  type: string;
  ratio: string;
  similarity: string;
  country: string;
  difference: string;
  itemsKey: string;
  note: string;
  items: Array<ProductReferenceItem>;
}

export interface ProductRestriction {
  id: number;
  country: string;
  status: string;
  comment: string;
}

export interface ReferencedMaterial {
  id: number;
  itemCode: string;
  source: string;
  manufacturerId: number;
  manufacturerName: string;
  texts: Array<{ [key: string]: string }>;
  productClass: string;
  salesStatus: string;
  marketingRelease: string;
  nationalDrugNumber: string;
  materialPackages: Array<MaterialPackage>;
  image?: string;
  quantity?: any;
  position?: any;
}
export interface ProductReferenceOverview {
  id: number;
  type: string;
  ratio: string;
  similarity: SimilarityTypes;
  country: string;
  difference: string;
  note: string;
  referencedMaterial: ReferencedMaterial;
  referencedMaterials?: Array<ReferencedMaterial>;
}

export interface ProductAndAddons {
  details: Product;
  references: PagingResponse<ProductReference>;
  restrictions: PagingResponse<ProductRestriction>;
}

export interface ProductAndAddonPageConfig {
  icon: string;
  isSVGIcon: boolean;
  data: PagingResponse<any>;
  totalElements: number;
}

export interface RestrictionTypeInterface {
  value: RestrictionTypes;
  viewValue: string;
}

export interface ReferenceDifference {
  value: string;
  name: string;
}

export interface BaseReferenceItem {
  materialId: number;
  ratioType: string;
}

export interface OneToOneReferenceItem extends BaseReferenceItem {
  itemCode: string;
  description: string;
}

export interface OneToManyReferenceItem extends BaseReferenceItem {
  possition: number;
  quantity: number;
}

export interface FilteredProduct {
  id: number;
  name: string;
}

export enum Manufacturer {
  NAME = 'manufacturerName'
}

export interface Type {
  value: string;
  name: string;
}

export interface Similarity {
  value: string;
  name: string;
}

// Reference Screen Data Models
export interface ReferenceScreenDto {
  id: number;
  referenceId?: number;
  restrictionId?: number;
  competitorProductId: number;
  itemCode?: string;
  manufacturerName?: string;
  productClass?: string;
  nationalDrugNumber?: string;
  texts?: Array<ProductDescription>;
  //references
  bbraunReferenceCode?: string;
  bbraunReferenceDescription?: string;
  similarity?: string;
  difference?: string;
  country?: string;
  type?: string;
  comment?: string;
  salesStatus: string;
  marketingRelease: string;
}

export class ReferenceSearchDto {
  itemCode?: string;
  manufacturerName?: string;
  description?: string;
  productClass?: string;
  nationalDrugNumber?: string;
  type?: string;
  country?: string;
  comment?: string;
  difference?: string;
}

export class CreateRestrictionsDto {
  materialIds: Array<number>;
  restriction: ProductRestriction;
}

export class CreateReferencesDto {
  materialId: number;
  reference: Partial<{
    country: string;
    difference: string;
    id: number;
    items: Array<{
      materialId: number;
      ratioType: string;
    }>;
    note: string;
    ratio: string;
    similarity: string;
    type: string;
  }>;
}

export class CreateReferencesResultDto {
  references: Array<ProductRestriction>;
  invalidChange: boolean;
  invalidMaterialIds: Array<number>;
  errorMessages: Array<string>;
}

export class ChangeReferencesToRestrictionsDto {
  referenceId: number;
  materialId: number;
  restriction: ProductRestriction;
}

export class ChangeReferencesToRestrictionResultDto {
  restrictions: Array<ProductRestriction>;
  invalidChange: boolean;
  invalidReferenceIds: Array<number>;
  errorMessages: Array<string>;
}

export class ChangeReferencesDto {
  referenceId: number;
  materialId: number;
  reference: Partial<{
    country: string;
    difference: string;
    id: number;
    items: Array<{
      materialId: number;
      ratioType: string;
    }>;
    note: string;
    ratio: string;
    similarity: string;
    type: string;
  }>;
}
export class ChangeReferencesResultDto {
  references: Array<ProductReference>;
  invalidChange: boolean;
  invalidReferenceIds: Array<number>;
  errorMessages: Array<string>;
}

export class DeleteCompetitorProductsResultDto {
  invalidChange: boolean;
  invalidCompetitorProductIds: Array<number>;
  invalidReferenceIds: Array<number>;
  invalidRestrictionIds: Array<number>;
}
