import { Pipe, PipeTransform } from '@angular/core';
import {Observable, of} from 'rxjs';

@Pipe({
  name: 'highlightText'
})
export class HighlightTextPipe implements PipeTransform {
  transform(value: string, searchText?: string): Observable<string> {

    // Remove the (*, ") symbol from searchText
    if (searchText?.includes('*')) {
      searchText = searchText.replace(/\*/g, '');
    }
    if (searchText?.includes('"')) {
      searchText = searchText.replace(/\"/g, '');
    }

    const regex = new RegExp(searchText, 'i');
    return of(
      value.replace(
        regex,
        (match) => `<span class='highlightText'>${match}</span>`
      )
    );
  }
}
